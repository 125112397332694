import Image from 'components/common/Image'
// eslint-disable-next-line no-restricted-imports
import { StaticImageData } from 'next/image'
import Link from 'components/common/Link'
import { ArrowRightIcon } from 'components/icons'

const BannerCardPage = ({
  bannerImage,
  altText,
  subtitle,
  heading,
  children,
  button,
}: {
  bannerImage: StaticImageData,
  altText: string,
  subtitle: string,
  heading: string,
  children: JSX.Element,
  button?: {
    href: string,
    text: string,
    target?: string,
  }
}) => {
  return (
    <div className="relative flex flex-col items-center justify-center h-screen">
      <div className="relative w-full h-full">
        <Image
          src={bannerImage}
          alt={altText}
          className="object-cover"
          layout="fill"
        />
      </div>
      <div className="absolute max-w-[1240px] w-full -mt-100 lg:mt-0 px-20 lg:px-0">
        <div className="sm:max-w-[686px] w-full bg-white shadow-availability-form rounded-4 px-25 py-30 sm:px-60 sm:py-80 mx-auto lg:mx-0">
          <div className="max-w-[525px] flex flex-col items-center sm:items-start">
            <p className="font-bold uppercase text-grey-750 text-14 tracking-1/2 leading-20">
              {subtitle}
            </p>
            <h2 className="font-serif font-bold leading-title tracking-title text-32 md:text-48 text-center sm:text-left max-w-[287px] sm:max-w-full mt-20 mb-20">
              {heading}
            </h2>
            <div className="flex flex-col gap-3 antialiased font-normal text-14 text-grey-750">
              {children}
            </div>
            {
              button
                && <Link
                  href={button.href}
                  className="flex items-center gap-16 py-18 px-[28px] border border-black border-opacity-30 rounded-4 hover:bg-grey-900 hover:text-white hover:border-grey-900 uppercase font-bold text-14 text-grey-900 tracking-1/2 leading-20 mt-30"
                  target={button.target}
                >
                  <ArrowRightIcon /> {button.text}
                </Link>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerCardPage
