import banner from 'assets/images/banner.png'
import BannerCardPage from 'components/layout/BannerCardPage'
import { NextSeo } from 'next-seo'
import { config } from 'data/config'

const IndexPage = () => {
  return (
    <>
      <NextSeo
        title="Browse Portfolio | Luxury Rentals"
        openGraph={{
          type: 'website',
          url: config.whitelabelURL,
          images: [
            {
              url: `${config.whitelabelURL}${banner?.src}`,
              width: 922,
              height: 700,
            },
          ],
        }}
      />

      <BannerCardPage
        bannerImage={banner}
        altText="Aerial vista of a Villa on the coast"
        subtitle="browseportfolio.com"
        heading="Join us as a valued travel partner"
        button={{
          href: config.travelAgentInquiryFormLink,
          text: 'Become a Travel Partner',
          target: '_blank',
        }}
      >
        <>
          <p>Gain access to 1000+ professionally managed luxury vacation rentals.</p>
          <p>We offer commission to all of our travel partners and have streamlined the booking process, providing a best-in-class B2B experience and white-labeled solution.</p>
          <p>If you are a travel agent and would like to work with us, apply here.</p>
        </>
      </BannerCardPage>
    </>
  )
}

export default IndexPage
